import {
	Badge,
	Box,
	Button,
	Inline,
	Heading,
	Text,
	Icons,
	Stack,
	Container,
	Col,
	Grid,
	joinAttributes,
	Spinner,
	Component,
	Money,
	Alert,
} from "@sembark-travel/ui/base"
import {
	ConfirmationDialog,
	ConfirmationDialogProvider,
	Dialog,
	useDialog,
} from "@sembark-travel/ui/dialog"
import {
	queryToSearch,
	useGetBackUrlFromLocation,
	Breadcrumbs,
	Link,
} from "@sembark-travel/ui/router"
import { Markdown } from "@sembark-travel/ui/markdown"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateString,
	dateToQuery,
	getDiff,
	startOf,
	dateToUTCString,
	endOf,
	localOrUtcTimestampToLocalDate,
	formatDate,
	parseDate,
	getDiffBetweenLocalAndUtcTimestamp,
} from "@sembark-travel/datetime-utils"
import { isNumeric, withOrdinalSuffix } from "@sembark-travel/number-utils"
import React, { useCallback, useState } from "react"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath, joinPaths } from "../router-utils"
import { EditScheduleInDialog } from "./EditItem"
import { type ICabSchedule, useScheduleDetails } from "./store"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { useXHR } from "@sembark-travel/xhr"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { IPaymentable } from "../Payments"

export function CabScheduleItem({ scheduleId }: { scheduleId: string }) {
	const { data, mutate: revalidate } = useScheduleDetails(scheduleId)
	const backUrl =
		useGetBackUrlFromLocation() ||
		generatePath("/cab-schedules") +
			queryToSearch({
				date: data
					? dateToQuery(
							localOrUtcTimestampToLocalDate(
								data.data.start_date_local,
								data.data.start_date
							)
						)
					: undefined,
			})
	if (!data) return <Spinner padding="4" alignCenter />
	const { id, transport_service } = data.data
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Schedule Details"
				items={[
					[backUrl, "Cab Schedules"],
					["", transport_service.name],
				]}
			/>
			<Container paddingY="6" bgColor="default">
				<ScheduleDetails schedule={data.data} onRefresh={revalidate} />
			</Container>
		</Box>
	)
}

function useDeleteSchedule(bookingId: number | string) {
	const xhr = useXHR()
	return async function () {
		return xhr
			.delete<{ message: string }>(`/cab-schedules/${bookingId}`)
			.then((resp) => resp.data)
	}
}

export function ScheduleDetails({
	schedule: propSchedule,
	disableEdit,
	onRefresh,
	actions = null,
	onDeletion,
	onShare,
}: {
	schedule: ICabSchedule
	disableEdit?: boolean
	onRefresh?: () => void
	actions?: React.ReactNode
	onDeletion?: () => void
	onShare?: () => void
}) {
	const { data, mutate: revalidate } = useScheduleDetails(propSchedule.id, {
		start_date: dateToUTCString(
			startOf(utcTimestampToLocalDate(propSchedule.start_date), "day")
		),
		end_date: dateToUTCString(
			endOf(utcTimestampToLocalDate(propSchedule.end_date), "day")
		),
	})
	const onChange = useCallback(() => {
		revalidate()
		onRefresh?.()
	}, [revalidate, onRefresh])
	const { hasPermission } = useCheckPermissions()
	if (!data) return <Spinner padding="4" alignCenter />
	const {
		data: schedule,
		meta: { itinerary },
	} = data
	const {
		id,
		start_date,
		start_date_local,
		start_time_local,
		transport_service,
		trip,
		cabs,
		can_modify,
		can_view_prices,
		duration_formatted,
		remarks,
		booked_cabs_count,
	} = schedule
	const timezoneDiff = getDiffBetweenLocalAndUtcTimestamp(
		trip.start_date_local,
		trip.start_date
	)
	const showOpsTeam = hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS)
	return (
		<Stack gap="6">
			{timezoneDiff ? (
				<Alert status="warning" title="Trip details in Different Timezone">
					The Timezone of this trip differs from your local timezone by{" "}
					{timezoneDiff} mins. Please avoid modifying details of this trip.
				</Alert>
			) : null}
			<Inline
				collapseBelow="md"
				gap="6"
				justifyContent="between"
				flexWrap="wrap"
			>
				<Stack gap="1">
					<Heading as="h3">{transport_service.from_to}</Heading>
					<Text fontWeight="semibold" fontSize="md" color="accent">
						{transport_service.service}
					</Text>
				</Stack>
				<Inline gap="2">
					<Box>
						<Icons.Calendar color="muted" size="6" />
					</Box>
					<Stack gap="1">
						<Box fontSize="md" fontWeight="semibold">
							{formatDate(
								localOrUtcTimestampToLocalDate(start_date_local, start_date),
								"ddd Do MMM, YYYY"
							)}
						</Box>
						<Inline gap="2" alignItems="center">
							{trip ? (
								<Box>
									<Badge primary outlined>
										{withOrdinalSuffix(
											getDiff(
												localOrUtcTimestampToLocalDate(
													start_date_local,
													start_date
												),
												localOrUtcTimestampToLocalDate(
													trip.start_date_local,
													trip.start_date
												),
												"days"
											) + 1
										)}{" "}
										Day
									</Badge>
								</Box>
							) : null}
							{(
								start_date_local
									? start_time_local && start_time_local !== "00:01:00"
									: utcTimestampToLocalDateString(start_date, "HH:mm") !==
										"00:01"
							) ? (
								<Text>
									{start_time_local
										? formatDate(
												parseDate(start_time_local, "HH:mm:ss"),
												"HH:mm [hrs]"
											)
										: utcTimestampToLocalDateString(start_date, "HH:mm [hrs]")}
									{duration_formatted ? ` - ${duration_formatted}` : null}
								</Text>
							) : null}
						</Inline>
					</Stack>
				</Inline>
				{trip ? (
					<Inline gap="2">
						<Box>
							<Icons.User color="muted" size="6" />
						</Box>
						<Stack gap="1">
							<Inline flexWrap="wrap" gap="2" alignItems="center">
								{trip.tourist ? (
									<Text fontWeight="semibold" fontSize="md">
										{trip.tourist.name}
									</Text>
								) : (
									<Text color="warning" fontSize="sm">
										<Icons.Attention /> Guest details missing!
									</Text>
								)}
								<Box color="muted" fontSize="sm">
									{joinAttributes(
										trip.tourist?.phone_numbers?.length ? (
											<PhoneNumber
												value={trip.contact.phone_numbers}
												iconOnly
											/>
										) : null,
										trip.tourist?.email ? (
											<Email value={trip.contact.email} iconOnly />
										) : null,
										`${trip.no_of_adults}A${
											trip.children.length ? `, ${trip.children.length}C` : ``
										}`
									)}
								</Box>
							</Inline>
							<Box color="muted">
								{joinAttributes(
									<Link
										to={joinPaths(
											generatePath("/trips/:tripId/services-bookings", {
												tripId: trip.id.toString(),
											}) + `/cabs`
										)}
										color="accent"
									>
										Trip {trip.id}
									</Link>,
									trip.trip_source.short_name,
									trip.trip_source_contact ? (
										<PhoneNumber
											value={trip.trip_source_contact.phone_numbers}
											iconOnly
										/>
									) : null
								)}
							</Box>
							{showOpsTeam ? (
								<Inline gap="2" alignItems="center" title="Operations Team">
									<Icons.Cog size="4" color="muted" opacity="50" />
									{trip.operations_team?.length ? (
										<Text fontSize="sm" textOverflow="truncate">
											{trip.operations_team
												.map((t, _, arr) =>
													arr.length > 1 ? t.name.slice(0, 4) : t.name
												)
												.join(", ")}
										</Text>
									) : (
										<Text fontSize="xs">N/A</Text>
									)}
								</Inline>
							) : null}
							{trip.due_payments_count ===
							undefined ? null : trip.due_payments_count > 0 ? (
								<Box>
									<Badge warning>Payments Due</Badge>
								</Box>
							) : (
								<Box>
									<Badge success title="Payments Received">
										Payments Clear
									</Badge>
								</Box>
							)}
						</Stack>
					</Inline>
				) : null}
			</Inline>
			<Stack as="ul" gap="6">
				{cabs.map(({ confirmation_details, ...c }) => (
					<Stack
						key={c.id}
						as="li"
						borderWidth="1"
						gap="2"
						rounded="lg"
						paddingX="4"
						paddingY="2"
						position="relative"
						borderColor={c.booked ? "success" : "default"}
					>
						<Grid gap="2">
							<Col sm={12} lg={5}>
								<Stack gap="1">
									<Text fontWeight="semibold" fontSize="md">
										{c.cab?.name || c.cab_type.name}{" "}
										{c.booked ? <Icons.OkCircleSolid color="success" /> : null}
										{!c.cab && c.cab_type.deleted_at ? (
											<Icons.Ban color="warning" title="Disabled" />
										) : null}
									</Text>
									{c.cab?.number_plate ? (
										<Text fontSize="sm" color="muted">
											{c.cab.number_plate.toUpperCase()}
										</Text>
									) : null}
									{confirmation_details ? (
										<Box
											whiteSpace="preserveAndWrap"
											borderWidth="1"
											paddingY="1"
											paddingX="2"
											rounded="md"
											fontSize="sm"
											bgColor="inset"
										>
											{confirmation_details}
										</Box>
									) : null}
								</Stack>
							</Col>
							{c.driver ||
							c.transport_service_provider ||
							(c.booked_price && can_view_prices) ? (
								<Col>
									<Inline justifyContent="between" gap="4">
										<Grid gap="4">
											{c.transport_service_provider ? (
												<Col>
													<Link
														to={generatePath(
															"/transport-service-providers/:transportServiceProviderId",
															{
																transportServiceProviderId:
																	c.transport_service_provider.id.toString(),
															}
														)}
													>
														{c.transport_service_provider.name}
													</Link>
													{c.transport_service_provider.contacts &&
													c.transport_service_provider.contacts.length ? (
														<Box color="muted" fontSize="sm">
															{joinAttributes(
																...c.transport_service_provider.contacts.map(
																	(t) => <PhoneNumber value={t.phone_numbers} />
																)
															)}
														</Box>
													) : null}
												</Col>
											) : null}
											{c.driver ? (
												<Col>
													<Stack>
														<Box title="Driver">
															<Icons.SteeringWheel /> {c.driver.name}
														</Box>
														<Box color="muted" fontSize="sm">
															{joinAttributes(
																c.driver.phone_number ? (
																	<PhoneNumber value={c.driver.phone_numbers} />
																) : null,
																c.driver.email ? (
																	<Email value={c.driver.email} iconOnly />
																) : null
															)}
														</Box>
													</Stack>
												</Col>
											) : null}
										</Grid>
										{can_view_prices ? <BookingAmount booking={c} /> : null}
									</Inline>
								</Col>
							) : null}
						</Grid>
					</Stack>
				))}
			</Stack>

			{itinerary?.description ? (
				<Component initialState={false}>
					{({ state, setState }) => (
						<Stack gap="1">
							<Box>
								<Button
									title={itinerary.name}
									inline
									onClick={() => setState(!state)}
								>
									<Text as="span" fontWeight="semibold" color="primary">
										Itinerary
									</Text>
									<Text as="span">
										<Icons.ChevronDown
											rotate={state ? "180" : "270"}
											transition="1"
										/>
									</Text>
								</Button>
							</Box>
							{state ? (
								<Stack color="muted" fontSize="sm" gap="1">
									<Text fontWeight="semibold">{itinerary.name}</Text>
									<Markdown>{itinerary.description}</Markdown>
								</Stack>
							) : null}
						</Stack>
					)}
				</Component>
			) : null}
			{remarks ? (
				<Stack gap="1">
					<Text>Remarks for Supplier and Customer</Text>
					<blockquote>{remarks}</blockquote>
				</Stack>
			) : null}
			{!disableEdit && onShare ? (
				<Box paddingTop="4" borderTopWidth="1">
					<Inline justifyContent="between" gap="4" flexWrap="wrap">
						<Inline gap="4">
							{!disableEdit && can_modify ? (
								<EditScheduleInDialog scheduleId={id} onSuccess={onChange}>
									{({ onEdit }) => (
										<Button onClick={() => onEdit()}>
											<Icons.Pencil /> Edit
										</Button>
									)}
								</EditScheduleInDialog>
							) : null}
							{onShare ? (
								<Button onClick={onShare}>
									<Icons.Share /> Share
								</Button>
							) : null}
							{actions}
						</Inline>
						{!disableEdit && can_modify && !booked_cabs_count ? (
							<DeleteCabScheduleInDialog
								id={propSchedule.id}
								onSuccess={onDeletion}
							>
								{({ onDelete, isDeleting }) => (
									<Button
										status="warning"
										disabled={isDeleting}
										onClick={() => {
											onDelete()
										}}
									>
										{isDeleting ? (
											<>Deleting...</>
										) : (
											<>
												<Icons.Trash />
												Delete
											</>
										)}
									</Button>
								)}
							</DeleteCabScheduleInDialog>
						) : null}
					</Inline>
				</Box>
			) : null}
		</Stack>
	)
}

export function DeleteCabScheduleInDialog({
	id,
	onSuccess,
	children,
}: {
	id: number
	onSuccess?: () => void
	children: (props: {
		onDelete: () => void
		isDeleting: boolean
	}) => React.ReactNode
}) {
	const deleteSchedule = useDeleteSchedule(id)
	const [isDeleting, setIsDeleting] = useState(false)
	return (
		<ConfirmationDialogProvider>
			{({ confirm }) => (
				<>
					{children({
						isDeleting,
						onDelete: async () => {
							if (isDeleting) return
							if (await confirm()) {
								setIsDeleting(true)
								deleteSchedule()
									.then(({ message }) => {
										showSnackbar(message)
										onSuccess?.()
									})
									.catch((error) => {
										alert(error.message || "Something went wrong")
									})
									.then(() => {
										setIsDeleting(false)
									})
							}
						},
					})}
					<ConfirmationDialog
						title="Confirmation"
						destructiveAction
						acceptActionLabel="I understand, Delete It"
					>
						<Stack gap="2">
							<Text fontWeight="semibold" color="warning">
								<Icons.Attention /> This action can not be reversed.
							</Text>
							<Text>Are you sure you want to delete this Cab Schedule ?</Text>
						</Stack>
					</ConfirmationDialog>
				</>
			)}
		</ConfirmationDialogProvider>
	)
}

export function ScheduleDetailsInDialog({
	children,
	...props
}: React.ComponentProps<typeof ScheduleDetails> & {
	children: (props: { onShow: () => void }) => React.ReactNode
}) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ onShow: open })}
			{isOpen ? (
				<Dialog open onClose={close} title="Schedule Details" lg>
					<Dialog.Body paddingX="4">
						<ScheduleDetails
							{...props}
							onDeletion={() => {
								close()
								props.onDeletion ? props.onDeletion() : props.onRefresh?.()
							}}
						/>
					</Dialog.Body>
				</Dialog>
			) : null}
		</>
	)
}

function BookingAmount({
	booking,
}: {
	booking: IPaymentable & {
		currency: string
		booked_price?: number
	}
}) {
	const {
		currency,
		booked_price,
		payments_amount,
		refunding_payments_amount,
		cancellation_charges,
		paid_payments_amount,
		paid_refunding_payments_amount,
	} = booking
	if (
		!isNumeric(booked_price) &&
		!isNumeric(payments_amount) &&
		!isNumeric(paid_payments_amount) &&
		!isNumeric(refunding_payments_amount)
	) {
		return null
	}
	return (
		<Stack alignItems={"end"}>
			{isNumeric(booked_price) ? (
				<Box fontWeight="semibold">
					<Money amount={booked_price} currency={currency} showCurrency />
				</Box>
			) : null}
			{isNumeric(payments_amount) ? (
				<Box fontWeight="semibold">
					{isNumeric(refunding_payments_amount) &&
					isNumeric(cancellation_charges) ? (
						<Inline alignItems="center" gap="1">
							<Text fontSize="sm">Chargable:</Text>
							<Money amount={cancellation_charges} currency={currency} />
						</Inline>
					) : isNumeric(booked_price) &&
					  Number(payments_amount || 0) ===
							Number(booked_price || 0) ? null : (
						<Inline alignItems="center" gap="1">
							<Text fontSize="sm">Chargable:</Text>
							<Money amount={payments_amount} currency={currency} />
						</Inline>
					)}
				</Box>
			) : null}
			{isNumeric(paid_payments_amount) ? (
				<Inline fontWeight="semibold" alignItems="center" gap="1">
					<Text fontSize="sm">Paid:</Text>
					<Money amount={paid_payments_amount} currency={currency} />
				</Inline>
			) : null}
			{isNumeric(refunding_payments_amount) ? (
				<Inline fontWeight="semibold" alignItems="center" gap="1">
					<Text fontSize="sm">Refund:</Text>
					<Inline alignItems="center" gap="px">
						<Money
							amount={paid_refunding_payments_amount || 0}
							currency={currency}
						/>
						<Text>/</Text>
						<Money amount={refunding_payments_amount} currency={currency} />
					</Inline>
				</Inline>
			) : null}
		</Stack>
	)
}
