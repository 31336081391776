import {
	Box,
	Table,
	AsyncSelect,
	Inline,
	joinAttributes,
	Stars,
	Stack,
	Text,
} from "@sembark-travel/ui/base"
import {
	useSearch,
	ListView,
	Search,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
} from "@sembark-travel/ui/list"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import React, { Fragment, useState } from "react"
import { Omit } from "utility-types"
import { generatePath } from "../router-utils"
import { TTripDestination } from "../TripDestinations"
import { AddHotelDialog } from "./NewItem"
import { IHotel, hotelsXHR as XHR } from "./store"
import { useEffect } from "react"
import {
	isTruthy,
	SelectField,
	SwitchInputField,
} from "@sembark-travel/ui/form"
import { IRoomType, SelectRoomTypes } from "../RoomTypes"
import { IMealPlan, SelectMealPlans } from "../MealPlans"

type TFilters = TSearchParams & {
	disabled_only?: 1 | 0
	room_types?: Array<IRoomType>
	meal_plans?: Array<IMealPlan>
	star_categories?: Array<{ id: string; name: string }>
	uncategorized?: 1 | 0
}

type TFiltersInLocationQuery = TSearchParams & {
	disabled_only?: 1 | 0
	room_types?: Array<string>
	meal_plans?: Array<string>
	star_categories?: Array<string>
	uncategorized?: 1
}

function filtersToQuery(filters: TFilters): TFiltersInLocationQuery {
	const {
		q,
		page,
		disabled_only,
		room_types,
		meal_plans,
		star_categories,
		uncategorized,
	} = filters
	const query: TFiltersInLocationQuery = {}
	if (q) {
		query.q = q
	}
	if (page) {
		query.page = page
	}
	if (disabled_only) {
		query.disabled_only = 1
	}
	if (uncategorized) {
		query.uncategorized = 1
	}
	if (room_types?.length) {
		query.room_types = room_types.map((s) => `${s.id}_${s.name}`)
	}
	if (meal_plans?.length) {
		query.meal_plans = meal_plans.map((s) => `${s.id}_${s.name}`)
	}
	if (star_categories?.length) {
		query.star_categories = star_categories.map((s) => `${s.id}####${s.name}`)
	}
	return query
}

function queryToFilters(query: TFiltersInLocationQuery): TFilters {
	const {
		q,
		page,
		disabled_only,
		room_types,
		meal_plans,
		star_categories,
		uncategorized,
	} = query
	const filters: TFilters = {}
	if (q) {
		filters.q = q
	}
	if (page) {
		filters.page = page
	}
	if (disabled_only) {
		filters.disabled_only = 1
	}
	if (uncategorized) {
		filters.uncategorized = 1
	}
	if (star_categories?.length) {
		filters.star_categories = star_categories.map((s: string) => {
			const [id, ...name] = s.split("####")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as TFilters["star_categories"]
	}
	if (room_types?.length) {
		filters.room_types = room_types.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as TFilters["room_types"]
	}

	if (meal_plans?.length) {
		filters.meal_plans = meal_plans.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as TFilters["meal_plans"]
	}
	return filters
}

export function HotelsList({ actions }: { actions?: React.ReactNode }) {
	const [query, setQuery] = useLocationQuery<TFilters, TFiltersInLocationQuery>(
		{
			toQuery: filtersToQuery,
			fromQuery: queryToFilters,
		}
	)
	const [params, setParams] = useSearch<TFilters>(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Hotels"
				actions={actions}
				Filters={Filters}
				areAdvancedFiltersApplied={(params) => {
					const { disabled_only, uncategorized, ...otherParams } = params
					return (
						isTruthy(disabled_only) ||
						isTruthy(uncategorized) ||
						areAdvancedFiltersAppliedDefault(otherParams)
					)
				}}
			>
				<ListView<IHotel, TFilters>
					pageKey="/hotels-listing"
					fetch={(xhr, params) => {
						return XHR(xhr).getHotelsWithTotal({
							...params,
							disabled_only: isTruthy(params.disabled_only) ? 1 : null,
							room_types: params.room_types?.map((r) => r.id),
							meal_plans: params.meal_plans?.map((r) => r.id),
							star_categories: params.star_categories?.map((r) => r.id),
							uncategorized: isTruthy(params.uncategorized) ? 1 : null,
							include: "thumbs",
						})
					}}
					params={params}
					onPageChange={(page) => setParams({ ...params, page })}
				>
					{({ items: hotels }) => <HotelsTableView hotels={hotels} />}
				</ListView>
			</Search>
		</Fragment>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				select={SelectRoomTypes}
				name="room_types"
				label="Room Types"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={SelectMealPlans}
				name="meal_plans"
				label="Meal Plans"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={SelectHotelStarCategory}
				name="star_categories"
				label="Star Categories"
				multiple
			/>
			<SwitchInputField name="uncategorized" label="Include Uncategorized" />
			<SwitchInputField name="disabled_only" label="Disabled Only" />
		</Stack>
	)
}

export function HotelsTableView({ hotels }: { hotels: Array<IHotel> }) {
	return (
		<Table
			responsive
			bordered
			hover
			headers={["Name", "Meal Plans", "Room Type", "Child extra bed age"]}
			rows={hotels.map((hotel) => [
				<Inline gap="2">
					{hotel.thumb_image_url ? (
						<Link
							to={hotel.id.toString()}
							color="accent"
							fontWeight="semibold"
							anchored
						>
							<Box
								as="img"
								src={hotel.thumb_image_url}
								alt="Display"
								width="12"
								height="auto"
								borderWidth="1"
								rounded="md"
							/>
						</Link>
					) : null}
					<Stack>
						<Link
							to={hotel.id.toString()}
							color="accent"
							fontWeight="semibold"
							anchored
						>
							<Box>{hotel.name}</Box>
						</Link>
						<Box color="muted">
							{joinAttributes(
								hotel.group ? (
									<Link
										to={generatePath("/hotel-groups/:groupId", {
											groupId: hotel.group.id.toString(),
										})}
									>
										{hotel.group.name}
									</Link>
								) : null,
								hotel.location.short_name,
								hotel.stars ? <Stars stars={hotel.stars} /> : null
							)}
						</Box>
					</Stack>
				</Inline>,
				hotel.meal_plans.map((mealPlan) => mealPlan.name).join(" • "),
				hotel.room_types.map((roomType) => roomType.name).join(" • "),
				<Fragment>
					{hotel.extra_bed_child_age_start}-{hotel.extra_bed_child_age_end}
					yo
				</Fragment>,
			])}
		/>
	)
}

export function SelectHotels({
	tripDestinations,
	showInfo = true,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
	showInfo?: boolean
}) {
	const [newName, setNewName] = useState("")
	const xhr = useXHR()
	return (
		<Fragment>
			<AsyncSelect
				multiple
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				optionRenderer={({ option, created }) =>
					created ? (
						<Box>Add new Hotel "{option.name}"</Box>
					) : (
						<Box>
							<Box>{option.name}</Box>
							{option.location || option.stars_string ? (
								<Box fontSize="sm" color="muted">
									{joinAttributes(
										option.location.short_name,
										option.stars_string
									)}
								</Box>
							) : null}
						</Box>
					)
				}
				{...props}
				perFetchLimit={15}
				fetch={(q, { page }) =>
					XHR(xhr)
						.getHotels({
							limit: 15,
							page,
							q,
							trip_destinations: tripDestinations?.length
								? tripDestinations.map((t) => t.id)
								: null,
						})
						.then((resp) => resp.data)
				}
			/>
			{showInfo && !props.multiple && props.value ? (
				<Text fontSize="sm" color="muted">
					{joinAttributes(
						props.value?.location?.short_name,
						props.value.stars_string
					)}
				</Text>
			) : null}
			<AddHotelDialog
				open={!!newName}
				initialValues={{
					name: newName,
				}}
				onClose={() => {
					setNewName("")
				}}
			/>
		</Fragment>
	)
}

export function SelectHotelStarCategory({
	tripDestinations,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	return (
		<Fragment>
			<AsyncSelect
				{...props}
				cacheKey={`hotel-stars-${tripDestinations?.map((t) => t.id).join("-")}`}
				fetch={(q) =>
					XHR(xhr).getHotelStarCategories({
						q,
						trip_destinations: tripDestinations?.length
							? tripDestinations.map((t) => t.id)
							: null,
					})
				}
			/>
		</Fragment>
	)
}
