import {
	Box,
	Button,
	Icons,
	Inline,
	Stack,
	Heading,
	Text,
	Col,
	Divider,
	Grid,
	Time,
	Component,
	Select,
} from "@sembark-travel/ui/base"
import {
	dateToUTCString,
	startOf,
	endOf,
	parseDate,
	getDiff,
	formatDate,
	addUnit,
	isSame,
	clone,
	localOrUtcTimestampToLocalDate,
	setTimeFrom,
	humanizeDuration,
} from "@sembark-travel/datetime-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	FieldArray,
	Form,
	TextInputField,
	SwitchInputField,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	DatePickerField,
	SelectField,
	arrayMutators,
	GetFieldValue,
	EmptyNumberValidator,
	MoneyInputField,
	TextAreaInputField,
	useFieldValue,
} from "@sembark-travel/ui/form"
import { useMemo, useState } from "react"
import { $PropertyType, Optional, Required } from "utility-types"
import * as Validator from "yup"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { SelectCabs } from "../Cabs"
import { SelectCabTypes } from "../CabTypes"
import { SelectDriver } from "../Drivers/List"
import { SelectTransporServiceProviders } from "../TransportServiceProviders"
import { ITransportServiceProvider } from "../TransportServiceProviders"
import { SelectTransportServices } from "../TransportServices"
import { SelectTrips } from "../Trips/List"
import { ICabSchedule } from "./store"
import { ITrip } from "../Trips"
import {
	useCurrenciesOfTenant,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import config from "../config"
import { TDriver } from "../Drivers"
import { WarnForExistingScheduledCabsForSupplier } from "./AssignServiceProviders"

function XHR(xhr: XHRInstance) {
	return {
		async createSchedule(data: unknown): Promise<Array<ICabSchedule>> {
			return xhr.post("/cab-schedules", data).then((resp) => resp.data.data)
		},
	}
}

export function CreateSchedule({
	date,
	onCancel,
	onSuccess,
	trip,
}: {
	date?: Date
	trip?: ScheduleFormProps["trip"]
	onCancel?: () => void
	onSuccess?: () => void
}) {
	const xhr = useXHR()
	return (
		<ScheduleForm
			trip={trip}
			pickup={date ? startOf(date, "day") : undefined}
			drop={date ? endOf(date, "day") : undefined}
			onSubmit={XHR(xhr).createSchedule}
			onSuccess={onSuccess}
			onCancel={onCancel}
			readOnlyTrip={Boolean(trip)}
		/>
	)
}

interface NewScheduleRequestData {
	pickup?: string | Date
	drop?: string | Date
	pickup_time?: string
	transportService?: $PropertyType<ICabSchedule, "transport_service">
	remarks?: string
	trip?: Pick<
		ITrip,
		| "id"
		| "destinations"
		| "start_date"
		| "start_date_local"
		| "end_date"
		| "end_date_local"
		| "pax_string"
	>
	cabs?: Array<
		Optional<
			Omit<$PropertyType<ICabSchedule, "cabs">[number], "trip" | "cab_schedule">
		>
	>
	readOnlyTrip?: boolean
}

const validationSchema = validateFormValues(
	Validator.object().shape({
		pickup: Validator.date().required("Pickup date/time is required"),
		// drop: Validator.date()
		//   .required("Pickup date/time is required")
		//   .when(
		//     "pickup",
		//     (value: Date | string | null, schema: Validator.DateSchema) =>
		//       !value ? schema : schema.min(value, "Drop should be after pickup")
		//   ),
		no_of_cabs: EmptyNumberValidator()
			.positive("Number of cabs should be positive integer")
			.typeError("Number of cab should be integer"),
		transport_service: Validator.object().required(
			"Transport service is required"
		),
		trip: Validator.object()
			.typeError("Trip is required.")
			.required("Trip is required."),
		cabs: Validator.array()
			.of(
				Validator.object().shape({
					cab_type: Validator.object()
						.typeError("Cab Type is required")
						.required("Cab Type is required."),
					transport_service_provider: Validator.mixed().nullable(),
					driver: Validator.mixed().nullable(),
					cab: Validator.mixed().nullable(),
					booked_price: EmptyNumberValidator().typeError(
						"Booked price should be a positive number"
					),
				})
			)
			.min(1, "Please provide atleast one cab's details"),
	})
)

interface ScheduleFormProps extends NewScheduleRequestData {
	onSubmit: (data: unknown) => Promise<ICabSchedule[]>
	onCancel?: () => void
	onSuccess?: () => void
}

export function ScheduleForm({
	pickup,
	drop,
	transportService,
	remarks,
	trip,
	cabs,
	onSubmit,
	onCancel,
	onSuccess,
	readOnlyTrip,
}: ScheduleFormProps) {
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const allCurrencies = useCurrenciesOfTenant()
	const [initialValues] = useState(() => {
		const currency = trip
			? trip.destinations.map((d) => d.currency).at(0)
			: functionalCurrency
		const initialCabs = [
			{
				id: null,
				cab_type: null,
				transport_service_provider: null,
				driver: null,
				cab: null,
				booked: false,
				booked_price: 0,
				calculated_price: 0,
				given_price: 0,
				already_booked: false,
				currency,
				confirmation_details: "",
			},
		] as unknown as Required<NewScheduleRequestData>["cabs"]
		const pickup_date = parseDate(
			pickup
				? pickup
				: startOf(
						trip
							? localOrUtcTimestampToLocalDate(
									trip.start_date_local,
									trip.end_date
								)
							: new Date(),
						"day"
					)
		)
		const drop_date = parseDate(
			drop ||
				endOf(
					trip
						? localOrUtcTimestampToLocalDate(trip.end_date_local, trip.end_date)
						: new Date(),
					"day"
				)
		)
		const duration = canAddDuration(pickup_date)
			? getDiff(drop_date, pickup_date, "minutes")
			: undefined
		return {
			pickup: pickup_date,
			pickup_time: canAddDuration(pickup_date)
				? formatDate(pickup_date, "HH:mm")
				: undefined,
			duration: duration || "",
			remarks: remarks,
			cabs: (cabs || initialCabs).map((c) => ({
				...c,
				already_booked: c.booked,
			})) as undefined | typeof initialCabs,
			transport_service: transportService,
			trip,
		}
	})
	const { hasPermission } = useCheckPermissions()
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			validate={validationSchema}
			onSubmit={withServerErrors(async (values) => {
				const start_date_local = startOf(values.pickup, "day")
				const start_time_local = values.pickup_time
					? parseDate(values.pickup_time, "HH:mm")
					: undefined
				const start_date_time_local = start_time_local
					? setTimeFrom(start_date_local, start_time_local)
					: addUnit(start_date_local, 1, "minute")

				const end_date_time_local =
					values.duration && start_time_local
						? addUnit(start_date_time_local, Number(values.duration), "minutes")
						: clone(start_date_time_local) // same as start_date_time_local
				const payload = {
					start_date: dateToUTCString(start_date_time_local),
					start_date_local: formatDate(start_date_time_local, "YYYY-MM-DD"),
					start_time_local: formatDate(
						start_date_time_local,
						config.timeFormat
					),
					end_date: dateToUTCString(end_date_time_local),
					end_date_local: formatDate(end_date_time_local, "YYYY-MM-DD"),
					end_time_local: formatDate(end_date_time_local, config.timeFormat),
					transport_service_id: values.transport_service
						? values.transport_service.id
						: null,
					remarks: values.remarks,
					cabs: values.cabs
						? values.cabs
								.filter((c): c is Required<typeof c, "cab_type"> =>
									Boolean(c.cab_type)
								)
								.map((c) => ({
									id: c.id,
									cab_type: c.cab_type.name,
									transport_service_provider: c.transport_service_provider?.id,
									driver: c.driver?.id,
									cab: c.cab?.id,
									booked_price: c.booked_price,
									given_price: c.given_price,
									calculated_price: c.calculated_price,
									booked: Number(c.booked),
									currency: c.currency,
									confirmation_details: c.confirmation_details,
								}))
						: [],
					trip_id: values.trip ? values.trip.id : null,
				}
				await onSubmit(payload)
				if (onSuccess) {
					onSuccess()
				} else {
					onCancel?.()
				}
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, form, handleSubmit }) => (
				<form noValidate id="cab_schedule_form" onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col sm={12} md={3}>
							<Inline gap="2">
								<Box>
									<Icons.Cog opacity="50" size="6" />
								</Box>
								<Stack gap="1">
									<Heading as="h3">Trip Details</Heading>
								</Stack>
							</Inline>
						</Col>
						<Col>
							{readOnlyTrip && trip ? (
								<Inline gap="6" flexWrap="wrap">
									<Stack gap="px">
										<Text fontSize="sm" color="muted" fontWeight="semibold">
											Destinations
										</Text>
										<Text>
											{trip.destinations.map((t) => t.name).join(", ")}
										</Text>
									</Stack>
									<Stack gap="px">
										<Text fontSize="sm" color="muted" fontWeight="semibold">
											Start Date
										</Text>
										<Text>
											<Time
												timestamp={trip.start_date}
												localTimestamp={trip.start_date_local}
											/>
										</Text>
									</Stack>
									<Stack gap="px">
										<Text fontSize="sm" color="muted" fontWeight="semibold">
											End Date
										</Text>
										<Text>
											<Time
												timestamp={trip.end_date}
												localTimestamp={trip.end_date_local}
											/>
										</Text>
									</Stack>
									<Stack gap="px">
										<Text fontSize="sm" color="muted" fontWeight="semibold">
											Pax
										</Text>
										<Text>{trip.pax_string}</Text>
									</Stack>
								</Inline>
							) : (
								<SelectField
									name="trip"
									select={SelectTrips}
									label="Select Trip"
									required
								/>
							)}
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={3}>
							<Inline gap="2">
								<Box>
									<Icons.LocationMarker opacity="50" size="6" />
								</Box>
								<Stack gap="1">
									<Heading as="h3">Service Details</Heading>
									<Text color="muted">
										Please provide transport service with and pickup and drop
										details.
									</Text>
								</Stack>
							</Inline>
						</Col>
						<Col>
							<Stack gap="4">
								<GetFieldValue<NewScheduleRequestData["trip"]> name="trip">
									{({ value: trip }) => (
										<SelectField
											name="transport_service"
											select={SelectTransportServices}
											creatable
											multiple={false}
											label="Select Transport Service"
											required
											tripDestinations={trip?.destinations}
										/>
									)}
								</GetFieldValue>
								<Grid gap="4">
									<Col xs={12} sm="auto">
										<GetFieldValue<NewScheduleRequestData["trip"]> name="trip">
											{({ value: trip }) => {
												const interval = trip
													? [
															localOrUtcTimestampToLocalDate(
																trip.start_date_local,
																trip.start_date
															),
															localOrUtcTimestampToLocalDate(
																trip.end_date_local,
																trip.end_date
															),
														]
													: undefined
												return (
													<DatePickerField
														label="Date"
														name="pickup"
														required
														min={interval ? interval[0] : undefined}
														max={interval ? interval[1] : undefined}
													/>
												)
											}}
										</GetFieldValue>
									</Col>
									<Col xs={12} sm="auto">
										<SelectField
											label="Pickup Time"
											secondaryLabel="24 hrs - optional"
											minWidth="auto"
											select={Select}
											name="pickup_time"
											clearable={true}
											placeholder="14:00"
											menuPlaceholder="Type time and select"
											creatable
											createOptionLabel={(query) => {
												let date
												try {
													date = parseDate(query, "HH:mm")
												} catch (e) {
													date = undefined
												}
												return date
													? `Set: ${formatDate(date, "HH:mm")} hrs`
													: "Invalid Time"
											}}
											closeOnSelect
											onCreateNew={(query) => {
												let date
												try {
													date = parseDate(query, "HH:mm")
												} catch (e) {
													date = undefined
												}
												return date ? formatDate(date, "HH:mm") : ""
											}}
										/>
									</Col>
									<GetFieldValue<Date | undefined> name="pickup">
										{({ value: pickupDate }) => (
											<GetFieldValue<string | undefined> name="pickup_time">
												{({ value: pickupTime }) => {
													const pickupDateTime =
														pickupDate && pickupTime
															? setTimeFrom(
																	pickupDate,
																	parseDate(pickupTime, "HH:mm")
																)
															: undefined
													return (
														<Col
															xs={12}
															sm="auto"
															hidden={
																!pickupDateTime ||
																!canAddDuration(pickupDateTime)
															}
														>
															<TextInputField
																type="number"
																label="Duration (mins)"
																name="duration"
																secondaryLabel="optional"
																placeholder="e.g. 240"
																help={
																	<GetFieldValue<
																		number | undefined
																	> name="duration">
																		{({ value }) => {
																			if (pickupDateTime && value) {
																				const drop = addUnit(
																					pickupDateTime,
																					value,
																					"minutes"
																				)
																				return (
																					<Text
																						color={
																							!isSame(
																								drop,
																								pickupDateTime,
																								"day"
																							)
																								? "danger"
																								: undefined
																						}
																					>
																						End Date:{" "}
																						{formatDate(drop, "D MMM, HH:mm")} (
																						{humanizeDuration(
																							getDiff(
																								drop,
																								pickupDateTime,
																								"millisecond"
																							)
																						)}
																						)
																					</Text>
																				)
																			}
																			return null
																		}}
																	</GetFieldValue>
																}
															/>
														</Col>
													)
												}}
											</GetFieldValue>
										)}
									</GetFieldValue>
								</Grid>
								<GetFieldValue<string | undefined> name="remarks">
									{({ value }) => (
										<Component initialState={value ? true : false}>
											{({ state, setState }) =>
												!state ? (
													<Box>
														<Button size="sm" onClick={() => setState(true)}>
															<Icons.Plus /> Add Remarks
														</Button>
													</Box>
												) : (
													<Box maxWidth={"lg"}>
														<TextAreaInputField
															name={`remarks`}
															autoFocus={value ? false : true}
															label="Remarks for Supplier and Customer"
															secondaryLabel="optional"
															placeholder="Any notes/remarks that should be shared with Supplier and Customer..."
														/>
													</Box>
												)
											}
										</Component>
									)}
								</GetFieldValue>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={3}>
							<Inline gap="2">
								<Box>
									<Icons.Bus opacity="50" size="6" />
								</Box>
								<Stack gap="1">
									<Heading as="h3">Cabs & Drivers</Heading>
									<Text color="muted">
										Please provide cab details along with drivers and service
										providers if booked.
									</Text>
								</Stack>
							</Inline>
						</Col>
						<Col>
							<FieldArray<
								Required<NewScheduleRequestData, "cabs">["cabs"][number]
							> name="cabs">
								{({ fields }) => (
									<>
										{fields.map((name, index) => (
											<Box display="flex" key={index}>
												{Number(fields.length) > 1 ? (
													<Box
														paddingX="2"
														paddingY="4"
														borderRightWidth="1"
														bgColor="inset"
														borderBottomWidth="1"
														borderLeftWidth="1"
														borderTopWidth={index === 0 ? "1" : undefined}
														fontWeight="semibold"
														style={{
															fontFeatureSettings: "tnum",
															fontVariantNumeric: "tabular-nums",
														}}
													>
														{index + 1}
													</Box>
												) : null}
												<Box
													flex="1"
													minWidth="0"
													borderBottomWidth="1"
													paddingY="4"
													paddingLeft="4"
												>
													<Stack gap="4">
														<GetFieldValue<
															NewScheduleRequestData["trip"]
														> name="trip">
															{({ value: trip }) => (
																<GetFieldValue<number | undefined>
																	name={`${name}.id`}
																>
																	{({ value: existingId }) => (
																		<SelectField
																			name={`${name}.cab_type`}
																			select={SelectCabTypes}
																			creatable
																			multiple={false}
																			label="Cab Type"
																			tripDestinations={trip?.destinations}
																			secondaryLabel={
																				existingId ? `#${existingId}` : null
																			}
																			required
																		/>
																	)}
																</GetFieldValue>
															)}
														</GetFieldValue>
														<Grid gap="4">
															<Col xs={12} sm>
																<SelectField
																	name={`${name}.transport_service_provider`}
																	select={SelectTransporServiceProviders}
																	label="Service Provider"
																	creatable
																	onChange={(
																		value:
																			| ITransportServiceProvider
																			| undefined,
																		_name
																	) => {
																		form.batch(() => {
																			form.change(
																				_name as never,
																				value as never
																			)
																			if (value?.solo_driver) {
																				form.change(
																					`${name}.driver` as never,
																					value.drivers?.[0] as never
																				)
																			} else {
																				form.change(
																					`${name}.driver` as never,
																					undefined
																				)
																			}
																			form.change(
																				`${name}.cab` as never,
																				undefined
																			)
																		})
																	}}
																/>
															</Col>
															<GetFieldValue<
																Required<
																	NewScheduleRequestData,
																	"cabs"
																>["cabs"][number]["transport_service_provider"]
															>
																name={`${name}.transport_service_provider`}
															>
																{({ value: transport_service_provider }) =>
																	transport_service_provider ? (
																		<>
																			{!transport_service_provider.solo_driver ? (
																				<Col xs={12} sm>
																					<SelectField
																						name={`${name}.driver`}
																						select={SelectDriver}
																						label="Driver"
																						transportServiceProvider={
																							transport_service_provider
																						}
																						options={
																							transport_service_provider.drivers ||
																							[]
																						}
																						creatable
																					/>
																				</Col>
																			) : null}
																			<Col xs={12} sm>
																				<SelectField
																					name={`${name}.cab`}
																					select={SelectCabs}
																					label="Cab Details"
																					options={
																						transport_service_provider.cabs ||
																						[]
																					}
																					creatable
																				/>
																			</Col>
																		</>
																	) : null
																}
															</GetFieldValue>
														</Grid>
														<ExistingScheduledCabsForDriver
															scheduledCabFieldName={name}
															tripId={trip?.id}
														/>
														<Grid gap="4">
															<Col xs={12} sm={4}>
																<GetFieldValue<number | undefined>
																	name={`${name}.given_price`}
																>
																	{({ value: givenPrice }) => (
																		<MoneyInputField
																			currencyFieldName={`${name}.currency`}
																			name={`${name}.booked_price`}
																			label="Booked Price"
																			currencies={allCurrencies}
																			help={
																				givenPrice &&
																				hasPermission(
																					PERMISSIONS.MANAGE_TRIP_OWNERS
																				)
																					? `Selling price: ${givenPrice}`
																					: null
																			}
																		/>
																	)}
																</GetFieldValue>
															</Col>
															<Col>
																<SwitchInputField
																	name={`${name}.booked`}
																	label="Confirmed by Supplier"
																	help={
																		"Please select it when the cab is booked. After booking, instalments will be created for this booking."
																	}
																/>
															</Col>
														</Grid>
													</Stack>
													<Box marginTop="4">
														<Inline gap="2">
															<GetFieldValue<
																Required<
																	NewScheduleRequestData,
																	"cabs"
																>["cabs"][number]
															>
																name={name}
															>
																{({ value: cab }) => (
																	<Button
																		onClick={() =>
																			fields.push({
																				...cab,
																				id: undefined,
																			})
																		}
																		title="Duplicate"
																		size="sm"
																	>
																		<Icons.Duplicate /> Duplicate
																	</Button>
																)}
															</GetFieldValue>
															{Number(fields.length) > 1 ? (
																<Button
																	onClick={() => fields.remove(index)}
																	title="Remove"
																	size="sm"
																	level="tertiary"
																>
																	<Icons.Cancel /> Remove
																</Button>
															) : null}
														</Inline>
													</Box>
												</Box>
											</Box>
										))}
										<Box marginTop="4">
											<Button
												status="primary"
												onClick={() =>
													fields.push({
														...(initialValues.cabs?.[0] || {}),
														id: undefined,
														driver: undefined,
														cab: undefined,
														booked: false,
														booked_price: 0,
														calculated_price: 0,
														given_price: 0,
													})
												}
												size="sm"
											>
												{Number(fields.length) > 0
													? "Add More Cabs"
													: "Add Cab Details"}
											</Button>
										</Box>
									</>
								)}
							</FieldArray>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={{ offset: 3, span: 8 }}>
							<Stack gap="4" paddingLeft={{ md: "4" }}>
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting} size="lg">
										{submitting ? "Please wait..." : "Save Details"}
									</Button>
									{onCancel ? (
										<Button
											disabled={submitting}
											onClick={() => onCancel()}
											size="lg"
											level="tertiary"
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}

function canAddDuration(pickup: Date) {
	return formatDate(pickup, "HH:mm") !== "00:01"
}

function ExistingScheduledCabsForDriver({
	scheduledCabFieldName,
	tripId,
}: {
	scheduledCabFieldName: string
	tripId?: number | string
}) {
	const { value: pickupDate } = useFieldValue<Date | string | undefined>(
		"pickup"
	)
	const { value: pickupTime } = useFieldValue<string | undefined>("pickup_time")
	const { value: duration } = useFieldValue<number | undefined>("duration")
	const pickupDateStartOfDay = useMemo(() => {
		if (!pickupDate) return undefined
		return startOf(pickupDate, "day")
	}, [pickupDate])
	const { value: supplier } = useFieldValue<
		ITransportServiceProvider | undefined
	>(`${scheduledCabFieldName}.transport_service_provider`)
	const { value: existingId } = useFieldValue<number | undefined>(
		`${scheduledCabFieldName}.id`
	)
	const { value: driver } = useFieldValue<TDriver>(
		`${scheduledCabFieldName}.driver`
	)

	if (!pickupDateStartOfDay || !supplier) {
		return null
	}

	return (
		<WarnForExistingScheduledCabsForSupplier
			tripId={tripId}
			startDate={pickupDateStartOfDay}
			startTime={pickupTime ? parseDate(pickupTime, "HH:mm") : undefined}
			scheduledCabId={existingId}
			supplier={supplier}
			driver={driver}
			duration={duration}
		/>
	)
}
