import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import useSWR from "swr"
import { IUser } from "../Users"

export type TTaxType = {
	id: number
	name: string
	display_name: string
	description?: string
	created_at: string
	created_by?: IUser
	updated_at?: string
	updated_by?: IUser
	default_percentage_value?: number | null
	deleted_at?: string
}

export function taxTypesXHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown) {
			return xhr
				.get<IListResponse<TTaxType>>(`/tax-types`, { params })
				.then((resp) => resp.data)
		},
		async store(data?: unknown) {
			return xhr
				.post<{ data: TTaxType }>(`/tax-types`, data)
				.then((resp) => resp.data)
		},
		async destroy(id: number) {
			return xhr
				.delete<{ message: string }>(`/tax-types/${id}`)
				.then((resp) => resp.data)
		},
	}
}

export function useTaxTypes() {
	const xhr = useXHR()
	return useSWR(
		`/api/tax-types`,
		() =>
			taxTypesXHR(xhr)
				.get()
				.then((resp) => resp.data),
		{ revalidateOnFocus: false }
	)
}
